<template>
  <v-bottom-sheet v-if="dealerJSON" v-model="cookieDialog" hide-overlay>
    <v-sheet class="">
      <div class="cookie-wrapper page-size" :class="{mobileView: mobileView == true && showMobileMessage == false}">
        <div class="cookie-wrapper-inner">
          <div class="cookie-logo">
            <img alt="Logo" class="cookie-image" :src="dealerConfigData.theme.dir + dealerConfigData.theme.name + themeJSON.images.dir + themeJSON.images.cookieLogo" />
          </div>
          <div class="cookie-content">
            <h2>Cookie melding</h2>
            <div v-show="mobileView == false || showMobileMessage == true" class="pb-4 cookie-statement" v-html="dealerJSON.config.cookie_statement"></div>
            <div v-show="mobileView == true && showMobileMessage == false" class="pb-2">{{ dealerName }} gebruikt Cookies.</div>
          </div>
          <v-btn class="back-button font-weight-bold mt-2 mb-2" @click="$emit('acceptCookies')">Akkoord</v-btn>
          <v-btn v-if="mobileView == true && showMobileMessage == false" class="back-button font-weight-bold mt-2 mb-2 float-right" @click="showMobileMesg()">Meer info</v-btn>
        </div>
      </div>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import { mapState } from 'vuex'; // introducing mapState for store

export default {
  name: 'CookiePopUp',
  props: {
    cookieDialog: Boolean
  },
  data() {
    return {
      mobileView: false,
      showMobileMessage: false,
      dealerName: null
    }
  },
  mounted() {
    const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (windowWidth <= 600) {
      this.mobileView = true;
    }
  },
  computed: mapState({
    dealerJSON: state => state.dealerJSON, // get data from store
    dealerConfigData: state => state.dealerConfigData, // get data from store
    themeJSON: state => state.themeJSON
  }),
  methods: {
    showMobileMesg() {
      this.showMobileMessage = true;
    }
  }
};
</script>

<style lang="scss">
// cookie popup
.cookie-wrapper {
  padding: 24px;
  &.mobileView {
    padding-top: 14px !important;
    padding-bottom:4px !important;
    .cookie-logo {
      display: none;
    }
    h2 {
      min-height: auto !important;
    }
  }
  .cookie-wrapper-inner {
    position: relative;
    .cookie-logo {
      position: absolute;
      top: -16px;
      right: 0px;
      width: 100%;
      text-align: right;
      max-height: 64px;
      height: 100%;
      img {
        padding: 10px 0;
        height: 100%;
      }
    }
    h2 {
      min-height: 50px;
      padding-right: 80px;
    }
  }
}
</style>